import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popup from "reactjs-popup";
import useClickOutside from "../hooks/useClickOutside";
import { FormProps } from "../types/shared";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { FormTypes } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { setForm } from "../slices/form";
import { RootState } from "../store";
import { Tooltip } from "react-tooltip";
import WerkStationLogo from "../assets/images/werkstation_header_logo.svg";
import { setKeyword } from "../slices/search";

const IdeaForm = React.lazy(() => import("../components/IdeaForm"));
const ReportForm = React.lazy(() => import("../components/ReportForm"));
const ServiceForm = React.lazy(() => import("../components/ServiceForm"));

const formTypes = [
  {
    type: FormTypes.idea,
    title: "Submit an idea",
  },
  {
    type: FormTypes.report,
    title: "Report a bug / issue",
  },
  {
    type: FormTypes.service,
    title: "IT service request",
  },
];

const ContactModal = ({ close }: FormProps) => {
  const selectRef = useRef(null);
  const dispatch = useDispatch();
  useClickOutside(selectRef, close);
  return (
    <div
      ref={selectRef}
      className="top-[40px] right-0 absolute rounded-lg bg-white p-5 border-2 text-[#005F3E] w-[295px]"
    >
      <div className="pb-3 font-bold">Contact Our Team</div>
      <div className="pb-5 font-extralight">
        Reach us anytime and let us Werk together!
      </div>
      {formTypes.map(({ title, type }, i) => (
        <button
          key={i}
          onClick={() => {
            dispatch(setForm(type));
            close();
          }}
          className="border border-b-0 first:rounded-t last:border last:rounded-b border-[#EEEEEE] p-3 text-left w-full"
        >
          {title}
        </button>
      ))}
    </div>
  );
};

interface HeaderPropType {
  loggedIn: boolean;
}

const Header = ({ loggedIn }: HeaderPropType) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { type } = useSelector((state: RootState) => state.form);
  const { keyword, page, size } = useSelector(
    (state: RootState) => state.search
  );
  const [modal, setModal] = useState(false);

  const closeProps = () => {
    dispatch(setForm(null));
    navigate(window.location.pathname);
  };
  useEffect(() => {
    if (location.pathname.includes("/contact/") && params.type) {
      dispatch(setForm(params.type));
    }
  }, [params.type, location.pathname, dispatch]);
  useEffect(() => {
    if (keyword) navigate("global_search/");
  }, [keyword, page, size, navigate]);
  return (
    <nav className="px-2 py-2">
      <div className="flex justify-between lg:mx-44 md:mx-28 mx-11">
        <div className="flex items-center">
          <div></div>
          <div>
            <a href="/">
              <div className="float-right text-[#159B74]">
                <div className="text-[20px] font-bold">
                  <img src={WerkStationLogo} alt="WerkStation" />
                </div>
              </div>
            </a>
          </div>
        </div>
        {loggedIn && (
          <div className="flex items-center">
            <>
              <div className="mr-6 relative md:block hidden">
                <input
                  placeholder="Search"
                  type="text"
                  className="rounded-[6px] border border-boxBorder focus:border-rose-500 w-[221px] h-[46px] p-6 pr-10"
                  onKeyDown={(evt) => {
                    if (evt.code === "Enter") {
                      dispatch(
                        setKeyword((evt.target as HTMLInputElement).value)
                      );
                    }
                  }}
                />
                <FontAwesomeIcon
                  icon={["fas", "magnifying-glass"]}
                  className="absolute right-5 top-0 bottom-0 mx-0 my-auto"
                />
              </div>
              <FontAwesomeIcon
                icon={["fas", "magnifying-glass"]}
                className="md:hidden mr-10"
              />
            </>

            <div className="relative">
              <FontAwesomeIcon
                className="block py-2 hover:scale-125 ease-in duration-200 hover:text-green-200 bg-white"
                icon={["fas", "headset"]}
                size="lg"
                onClick={() => setModal((p: boolean) => !p)}
              />
              {modal && <ContactModal close={() => setModal(false)} />}
            </div>

            <>
              <a href="/oidc/logout/" data-tooltip-id="logout">
                <FontAwesomeIcon
                  className="ml-6 block py-2 hover:scale-125 ease-in duration-200 hover:text-green-200 bg-white"
                  icon={["fas", "arrow-right-from-bracket"]}
                  size="lg"
                />
              </a>
              <Tooltip id="logout" />
            </>
          </div>
        )}
      </div>
      <Popup
        open={type !== null}
        onClose={() => dispatch(setForm(null))}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        overlayStyle={{
          background: "#EEEEEE90",
        }}
        nested
      >
        {type === FormTypes.idea && <IdeaForm close={closeProps} />}
        {type === FormTypes.report && <ReportForm close={closeProps} />}
        {type === FormTypes.service && <ServiceForm close={closeProps} />}
      </Popup>
    </nav>
  );
};

Header.defaultProps = {
  loggedIn: true,
};

export default Header;
