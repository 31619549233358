import { IdeaDataType, OurServicesSelectOption } from "./idea";
import { ReportDataType } from "./report";

export interface FiledRequest {
  id: number | undefined;
  activity: OurServicesSelectOption | null | string;
  title: string;
  date_created?: string;
  filed_by?: string;
  assignedTo?: string;
  status: string | undefined;
  type: FilterButtons | undefined;
  user?: number | undefined;
  integration_id?: string | undefined;
}

export interface MemberFilter {
  id: number | undefined;
  filed_by: string | undefined;
}

export enum FilterButtons {
  All = "All",
  Requests = "Requests",
  BugReports = "Bug Reports",
}

export interface CustomFilterType {
  filedBy: string | undefined;
  user?: number | undefined;
  serviceType: FilterButtons;
}

export interface ServiceRequestRowProps {
  rowData?: IdeaDataType | ReportDataType | null | undefined;
  type: FilterButtons | undefined;
}

export interface TotalFiledType {
  all: number;
  bugs: number;
  requests: number;
}
