import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import PreviewCallbackPage from "./pages/PreviewCallbackPage";
import PrivateRoute from "./pages/PrivateRoute";
import React from "react";
import ProjectPage from "./pages/ProjectPage";
import ProjectPagePreview from "./pages/ProjectPage/ProjectPagePreview";
import EntityPage from "./pages/EntityPage";
import GroupsPagePreview from "./pages/GroupsPage/GroupsPreview";
import EntityPagePreview from "./pages/EntityPage/EntityPagePreview";
import ProjectsPreviewPage from "./pages/ProjectsPage/ProjectPagePreview";
import GoalsPagePreview from "./pages/GoalsPage/GoalsPagePreview";
import CustomPagePreview from "./pages/CustomPage/CustomPagePreview";
import DashboardPage from "./pages/DashboardPage";
import PostPage from "./pages/PostPage";
import PostPagePreview from "./pages/PostPage/PostPagePreview";
import ProjectPageByEntity from "./pages/ProjectPage/ProjectPageByEntity";
import CustomCardPagePreview from "./pages/CustomCardPage/CustomCardPagePreview";
import GlobalSearchPage from "./pages/GlobalSearchPage";
import ServiceRequestPage from "./pages/ServiceRequestPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <DashboardPage />,
      },
      {
        path: "global_search/",
        element: <GlobalSearchPage />,
      },
      {
        path: "contact/:type",
        element: <EntityPage />,
      },
      {
        path: "entities/:entityId/:path",
        element: <EntityPage />,
      },
      {
        path: "entities/:entityId",
        element: <EntityPage />,
      },
      {
        path: "/projects/:project",
        element: <ProjectPage />,
      },
      {
        path: ":entityId/projects/:project",
        element: <ProjectPageByEntity />,
      },
      {
        path: "posts/:postId",
        element: <PostPage />,
      },
      {
        path: "draft/preview/",
        element: <PreviewCallbackPage />,
      },
      {
        path: "draft/preview/entities/",
        element: <EntityPagePreview />,
      },
      {
        path: "draft/preview/entities/:path/",
        element: <EntityPagePreview />,
      },
      {
        path: "draft/preview/project/",
        element: <ProjectPagePreview />,
      },
      {
        path: "draft/preview/post/",
        element: <PostPagePreview />,
      },
      {
        path: "draft/preview/teams/",
        element: <GroupsPagePreview />,
      },
      {
        path: "draft/preview/projects/",
        element: <ProjectsPreviewPage />,
      },
      {
        path: "draft/preview/goals/",
        element: <GoalsPagePreview />,
      },
      {
        path: "draft/preview/custom/",
        element: <CustomPagePreview />,
      },
      {
        path: "draft/preview/custom_card/",
        element: <CustomCardPagePreview />,
      },
      {
        path: "support-center/",
        element: <ServiceRequestPage />,
      },
    ],
  },
]);
