import React, { useEffect, useState } from "react";
import {
  useGetBugReportsQuery,
  useGetServiceRequestsQuery,
} from "../../services";
import ServiceRequestFilters from "./ServiceRequestFilters";
import {
  CustomFilterType,
  FiledRequest,
  FilterButtons,
  MemberFilter,
  TotalFiledType,
} from "../../types/serviceRequest";
import ServiceRequestTable from "./ServiceRequestTable";
import moment from "moment";
const dateFormat = "YYYY-MM-DD hh:mm:ss";

const ServiceRequestPage = () => {
  const { data: serviceRequestData } = useGetServiceRequestsQuery(null);
  const { data: bugReportData } = useGetBugReportsQuery(null);
  const [trimmedServiceRequests, setTrimmedServiceRequests] =
    useState<FiledRequest[]>();
  const [trimmedBugReports, setTrimmedBugReports] = useState<FiledRequest[]>();
  const [members, setMembers] = useState<MemberFilter[]>([]);
  const [allRequestData, setAllRequestData] = useState<FiledRequest[]>();
  const [filteredData, setFilteredData] = useState<FiledRequest[]>();
  const [customFilteredData, setCustomFilteredData] =
    useState<FiledRequest[]>();
  const [totalFiled, setTotalFiled] = useState<TotalFiledType>({
    all: 0,
    bugs: 0,
    requests: 0,
  });

  const [customFiltered, setCustomFiltered] = useState<boolean>(false);
  /**
   * UseEffect for Transforming
   * SERVICE REQUEST data to fit
   * in Dashboard Column
   */
  useEffect(() => {
    const transformedServiceRequest = serviceRequestData?.map(
      ({
        id,
        idea,
        status,
        avail_service,
        integration_id,
        date_created,
        assigned_to,
        user,
      }) => ({
        title: idea,
        activity: avail_service,
        date_created: date_created
          ? moment(date_created).format(dateFormat)
          : "",
        filed_by: user ? `${user.first_name} ${user.last_name}` : "",
        type: FilterButtons.Requests,
        status,
        assigned_to,
        user: user ? user.id : undefined,
        id,
        integration_id,
      })
    );
    setTrimmedServiceRequests(transformedServiceRequest);
  }, [serviceRequestData]);

  /**
   * UseEffect for Transforming
   * BUG REPORT data to fit
   * in Dashboard Column
   */
  useEffect(() => {
    const transformedBugReport = bugReportData?.map(
      ({
        summary,
        status,
        integration_id,
        id,
        date_created,
        user,
        assigned_to,
      }) => ({
        title: summary,
        activity: "Bug Report",
        date_created: date_created
          ? moment(date_created).format(dateFormat)
          : "",
        filed_by: user ? `${user.first_name} ${user.last_name}` : "",
        type: FilterButtons.BugReports,
        status,
        assigned_to,
        id,
        integration_id,
        user: user ? user.id : undefined,
      })
    );
    setTrimmedBugReports(transformedBugReport);
  }, [bugReportData]);

  /**
   * UseEffect for combining
   * SERVICE REQUEST and BUG REPORT
   * into a DASHBOARD Data
   */
  useEffect(() => {
    if (
      Array.isArray(trimmedServiceRequests) &&
      Array.isArray(trimmedBugReports)
    ) {
      setAllRequestData([...trimmedServiceRequests, ...trimmedBugReports]);
      setFilteredData([...trimmedServiceRequests, ...trimmedBugReports]);

      setTotalFiled({
        all: trimmedServiceRequests.length + trimmedBugReports.length,
        requests: trimmedServiceRequests.length,
        bugs: trimmedBugReports.length,
      });

      const membersData = [...trimmedServiceRequests, ...trimmedBugReports].map(
        ({ filed_by, user }) => ({
          filed_by,
          id: user,
        })
      );
      const removeDuplicateMember = membersData.filter(
        (obj1, i, arr) => arr.findIndex((obj2) => obj2.id === obj1.id) === i
      );
      setMembers(removeDuplicateMember.sort());
    }
  }, [trimmedServiceRequests, trimmedBugReports]);

  useEffect(() => {
    if (customFiltered) {
      if (Array.isArray(customFilteredData)) {
        const requests = customFilteredData.filter(
          (data) => data.type === FilterButtons.Requests
        );
        const bugs = customFilteredData.filter(
          (data) => data.type === FilterButtons.BugReports
        );
        setTotalFiled({
          all: customFilteredData.length,
          requests: requests.length,
          bugs: bugs.length,
        });
      }
    } else {
      setTotalFiled({
        all: allRequestData ? allRequestData.length : 0,
        requests: trimmedServiceRequests ? trimmedServiceRequests.length : 0,
        bugs: trimmedBugReports ? trimmedBugReports.length : 0,
      });
    }
  }, [customFiltered, customFilteredData]);

  const FilterDataByUser = (
    user: number | undefined,
    data: FiledRequest[] | undefined
  ) => {
    if (user) return data?.filter((data) => data.user == user);
    return data;
  };

  const ButtonFilter = (serviceType: FilterButtons | undefined) => {
    if (customFiltered && Array.isArray(customFilteredData)) {
      switch (serviceType) {
        case "Requests":
          setFilteredData(
            customFilteredData.filter((data) => data.type === "Requests")
          );
          break;
        case "Bug Reports":
          setFilteredData(
            customFilteredData.filter((data) => data.type === "Bug Reports")
          );
          break;
        case "All":
          setFilteredData(customFilteredData);
          break;
      }
    } else {
      switch (serviceType) {
        case "Requests":
          setFilteredData(trimmedServiceRequests);
          break;
        case "Bug Reports":
          setFilteredData(trimmedBugReports);
          break;
        case "All":
          setFilteredData(allRequestData);
          break;
      }
    }
  };

  const CustomFilter = (filter: CustomFilterType) => {
    const { user, filedBy, serviceType } = filter;
    if (filedBy === "All" && serviceType === "All") {
      setCustomFiltered(false);
      setFilteredData(allRequestData);
    } else {
      setCustomFiltered(true);
      switch (serviceType) {
        case "Requests":
          setFilteredData(FilterDataByUser(user, trimmedServiceRequests));
          setCustomFilteredData(FilterDataByUser(user, trimmedServiceRequests));
          break;
        case "Bug Reports":
          setFilteredData(FilterDataByUser(user, trimmedBugReports));
          setCustomFilteredData(FilterDataByUser(user, trimmedBugReports));
          break;
        case "All":
          setFilteredData(FilterDataByUser(user, allRequestData));
          setCustomFilteredData(FilterDataByUser(user, allRequestData));
          break;
      }
    }
  };

  const findRequestById = (
    id: number | undefined,
    type: FilterButtons | undefined
  ) => {
    if (id) {
      switch (type) {
        case FilterButtons.Requests:
          return serviceRequestData?.find((service) => service.id === id);
        case FilterButtons.BugReports:
          return bugReportData?.find((bug) => bug.id === id);
      }
    }
    return null;
  };

  return (
    <div className="bg-boxGray pt-11 pb-[700px]">
      <div className="flex justify-between lg:mx-44 md:mx-28 mx-11 bg-white">
        <div className="p-5 p-b10 w-full h-full">
          <ServiceRequestFilters
            all={totalFiled.all}
            requests={totalFiled.requests}
            bug={totalFiled.bugs}
            mentions={0}
            membersFilter={members}
            onButtonFilter={(serviceType: FilterButtons) =>
              ButtonFilter(serviceType)
            }
            onCustomFilter={(filter: CustomFilterType) => CustomFilter(filter)}
          />
          <ServiceRequestTable
            filedRequests={filteredData}
            fetchRowData={findRequestById}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceRequestPage;
