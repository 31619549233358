import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: null,
};

const formSlice = createSlice({
  name: "form",
  initialState: initialState,
  reducers: {
    setForm: (state, action) => {
      state.type = action.payload;
    },
  },
});

const { setForm } = formSlice.actions;

const formReducer = formSlice.reducer;

export { formReducer, setForm };
