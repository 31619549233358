import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet-async";
import Skeleton from "react-loading-skeleton";
import NavigationContentContainer from "../../components/NavigationContentContainer";
import SanitizeHTML from "../../components/SanitizeHTML";
import SingleAccordion from "../../components/SingleAccordion";

import { CustomPageType } from "../../types/custom";
import { ErrorCode, ErrorResponse } from "../../types/error";
import { QueryResult } from "../../types/shared";
import { defaultHelmetTitle } from "../../constants";

const CustomPageComponent: FunctionComponent<QueryResult<CustomPageType>> = ({
  data,
  isFetching,
  isLoading,
  isError,
  error,
  isSuccess,
}) => {
  if (isError && error) {
    throw new ErrorResponse(error, ErrorCode.InternalError, "");
  }

  const isPageReady = !isFetching && !isLoading && isSuccess;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data?.title || defaultHelmetTitle}</title>
      </Helmet>
      <NavigationContentContainer
        isContentReady={isPageReady}
        header={data?.header || ""}
      >
        {isPageReady && data && (
          <div className="my-16 text-justify font-montserrat wysiwyg-content">
            <SanitizeHTML html={data.content} />

            {data.accordions.length > 0 &&
              data.accordions.map((accordion) => (
                <SingleAccordion
                  header={accordion.header}
                  key={accordion.id}
                  className="mb-2"
                  generalClass="text-xl"
                >
                  <div className="p-8">
                    <SanitizeHTML html={accordion.content} />
                  </div>
                </SingleAccordion>
              ))}
          </div>
        )}
        {!isPageReady && <Skeleton width="30%" />}
      </NavigationContentContainer>
    </>
  );
};

export default CustomPageComponent;
