import React, { FC, useState } from "react";
import { Comment as CommentIntfc } from "../types/comment";
import Button from "./Button";
import { memberData } from "../helper";
import useMemberInfo from "../hooks/useMemberInfo";
import moment from "moment";
import {
  useCreateCommentMutation,
  useGetCommentsQuery,
  useUpdateCommentMutation,
} from "../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTextEditor from "@opswerks-swg/text-editor";
import { setEditing } from "../slices/comment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import useAuthenticate from "../pages/PrivateRoute/useAuthenticate";
interface CommentsProps {
  type: string;
  id: number;
}

interface ComposeMessageProps {
  type?: string;
  typeId?: number;
  commentThreadId?: number;
  message?: string;
}

const Comments: FC<CommentsProps> = ({ type, id }) => {
  const { data: comments } = useGetCommentsQuery(
    {
      type,
      typeId: id,
    } ??
      (type && id)
  );
  return (
    <>
      <div className="flex flex-col gap-y-4">
        <ComposeMessage type={type} typeId={id} />
        <div className="mb-8">
          {comments &&
            comments.map((comment: CommentIntfc) => (
              <Comment {...comment} key={comment.id} type={type} typeId={id} />
            ))}
        </div>
      </div>
    </>
  );
};

const Comment: FC<CommentIntfc> = ({
  created_at,
  message,
  replies,
  member,
  thread,
  type,
  typeId,
  id,
}) => {
  const dispatch = useDispatch();
  const user = useAuthenticate();
  const [collapse, setCollapse] = useState(true);
  const [msg, setMsg] = useState(message);
  const [textEditor] = useTextEditor({
    data: msg,
    enableToolbar: false,
    readOnly: true,
  });
  const [updateComment] = useUpdateCommentMutation();
  const submitComment = async () => {
    if (id) {
      await updateComment({
        data: {
          message: msg,
          [type!]: typeId,
          ...(thread && { thread: thread }),
        },
        type: type!,
        id: id,
      }).finally(() => dispatch(setEditing(null)));
    }
  };
  const [editableTextEditor] = useTextEditor({
    data: msg,
    setData: setMsg,
    enableToolbar: true,
    onCancel: () => {
      setMsg(message);
      dispatch(setEditing(null));
    },
    onEnter: submitComment,
    readOnly: false,
  });
  const { id: commentId } = useSelector((state: RootState) => state.comment);

  return (
    <>
      <div className="w-full">
        <p className="flex items-center">
          <img
            src={memberData(member)?.profile_pic.url}
            className="rounded-full border border-white h-12 w-12 mr-2.5"
            alt={memberData(member)?.profile_pic.title}
          />
          <span className="font-bold">{memberData(member)?.first_name}</span>
          <span className="ml-2.5 text-textSecondary">
            {moment(created_at).fromNow()}
          </span>
        </p>
        <div className={commentId === id ? "block" : "hidden"}>
          {editableTextEditor}
        </div>
        {commentId !== id && <div>{textEditor}</div>}
      </div>
      {user.data &&
      member &&
      user.data.email === member.email &&
      (!commentId || commentId !== id) ? (
        <Button
          onClick={() => dispatch(setEditing(id))}
          styleProp="bg-primary text-white font-semibold w-fit mb-8 mr-2"
        >
          Edit
        </Button>
      ) : null}
      {collapse && !thread && (
        <Button
          onClick={() => setCollapse((prev) => !prev)}
          styleProp="bg-secondary text-textPrimary font-semibold w-fit mb-8"
        >
          Reply in thread{" "}
          {replies?.length
            ? `(${replies?.length} ${
                replies?.length > 1 ? "replies" : "reply"
              })`
            : ""}
          <FontAwesomeIcon icon={["fas", "chevron-down"]} className="ml-2" />
        </Button>
      )}
      {!collapse && (
        <div className="ml-16">
          {replies?.map((reply) => (
            <Comment {...reply} key={reply.id} type={type} typeId={typeId} />
          ))}
          <ComposeMessage type={type} typeId={typeId} commentThreadId={id} />
        </div>
      )}
    </>
  );
};

const ComposeMessage: FC<ComposeMessageProps> = ({
  type,
  typeId,
  commentThreadId,
  message: messageProp,
}) => {
  const memberInfo = useMemberInfo();
  const { refetch } = useGetCommentsQuery(
    {
      type: type!,
      typeId: typeId!,
    } ?? false
  );

  const [message, setMessage] = useState(messageProp ?? "");
  const [createComment, { isLoading: commentLoading }] =
    useCreateCommentMutation();
  const submitComment = async () => {
    const newComment = await createComment({
      data: {
        message,
        [type!]: typeId,
        ...(commentThreadId && { thread: commentThreadId }),
      },
      type: type!,
    }).unwrap();
    if (newComment.id) {
      refetch();
      setMessage("");
      reset();
    }
  };
  const [textEditor, reset] = useTextEditor({
    data: "",
    setData: setMessage,
    enableToolbar: true,
    onEnter: submitComment,
    readOnly: commentLoading,
  });

  return (
    <div className="flex gap-x-4 w-full my-8">
      <img
        src={memberData(memberInfo)?.profile_pic.url}
        className="rounded-full border border-white h-12 w-12"
        alt={memberData(memberInfo)?.profile_pic.title}
      />
      {textEditor}
    </div>
  );
};

export default Comments;
