import React, { ReactNode } from "react";

interface ButtonProps {
  children: ReactNode;
  styleProp?: string;
  disabled?: boolean;
  onClick?: (args: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button = ({
  children,
  styleProp,
  disabled = false,
  onClick,
}: ButtonProps) => (
  <button
    disabled={disabled}
    className={`rounded-md py-2 px-4 clickable-scale ${styleProp}`}
    onClick={onClick}
  >
    {children}
  </button>
);

export default Button;
