export const getCurrentYearQuarter = () => {
  const currentMonth = new Date().getMonth() + 1;
  if (currentMonth <= 3) {
    return "Q1";
  } else if (currentMonth <= 6) {
    return "Q2";
  } else if (currentMonth <= 9) {
    return "Q3";
  } else {
    return "Q4";
  }
};
